export interface VehiclePricingParams {
    msrp: number;
    isVehicleNew: boolean;
    pageType: 'SRP' | 'VDP';
    mileage: number;

    /** Called `FinalPrice` in SRP database, `ChapmanPrice` in VDP database */
    discountedPrice: number;

    isMsrpRequired: boolean;
    exclusiveDiscount: number;
    discounts: InventoryVehiclePricingDetails['discounts'];
}

export function useVehiclePricing({
    msrp = 0,
    isVehicleNew = false,
    pageType = 'SRP',
    mileage = 0,
    discountedPrice = 0,
    isMsrpRequired = false,
    exclusiveDiscount = 0,
    discounts = {},
}: Partial<VehiclePricingParams>) {
    const chapmanConnectStore = useChapmanConnectStore();
    const { arkonas } = useAppConfig();
    const isBmw =
        arkonas.site === 'caw' ||
        arkonas.site === 'cac' ||
        arkonas.site === 'hbmw';
    const isAudi = arkonas.site === 'ca1';
    const isMercedes = arkonas.site === 'cam';

    // #region LOCAL REFS
    // This composable is initialized in some places without real values due to
    // other pieces of code requiring the composable to exist before a car
    // exists.
    // Therefore, these local refs allow the initial data to be updated after a
    // car exists.
    const _msrp = ref<VehiclePricingParams['msrp']>(msrp);
    const _isVehicleNew =
        ref<VehiclePricingParams['isVehicleNew']>(isVehicleNew);
    const _pageType = ref<VehiclePricingParams['pageType']>(pageType);
    const _mileage = ref<VehiclePricingParams['mileage']>(mileage);
    const _discountedPrice =
        ref<VehiclePricingParams['discountedPrice']>(discountedPrice);
    const _isMsrpRequired =
        ref<VehiclePricingParams['isMsrpRequired']>(isMsrpRequired);
    const _exclusiveDiscount =
        ref<VehiclePricingParams['exclusiveDiscount']>(exclusiveDiscount);
    const _discounts = ref<VehiclePricingParams['discounts']>(discounts);

    /** Update all local car value refs. */
    function updateValues(data: VehiclePricingParams) {
        _msrp.value = data.msrp;
        _isVehicleNew.value = data.isVehicleNew;
        _pageType.value = data.pageType;
        _mileage.value = data.mileage;
        _discountedPrice.value = data.discountedPrice;
        _isMsrpRequired.value = data.isMsrpRequired;
        _exclusiveDiscount.value = data.exclusiveDiscount;
    }
    // #endregion LOCAL REFS

    // #region COMPUTED LOCAL REFS
    const validDiscounts = computed(() => {
        const entries = Object.entries(_discounts.value);

        const filtered = entries.filter(([_, value]) => {
            // Do not show tiny tiny savings
            if (value > 300) return true;
        });

        return Object.fromEntries(filtered);
    });
    // #endregion

    /**
     * The price before discounts are applied.
     */
    const initialPriceValue = computed(() => {
        if (!_msrp.value || _msrp.value <= 0) return 0;

        return _msrp.value;
    });

    /**
     * The initial price formatted like $234,000.
     */
    const initialPriceFormatted = computed(() => {
        return formatPrice(initialPriceValue.value);
    });

    /**
     * The label to the left of the initial price.
     */
    const initialPriceLabel = computed(() => {
        // For all pages with new vehicles.
        if (_isVehicleNew.value) {
            return 'MSRP';
        }

        // If used, and page is SRP
        if (_pageType.value === 'SRP' && !isBmw) {
            return 'Was';
        }

        // If used, and page is VDP
        return 'Retail';
    });

    /**
     * The actual final price after all discounts.
     */
    const discountedPriceValue = computed(() => {
        /**
         * Show initial price if savings are restricted and customer info does
         * not exist.
         */
        if (_isMsrpRequired.value && !chapmanConnectStore.hasCustomerData) {
            return initialPriceValue.value;
        }

        /**
         * Show price with exclusive discount if customer info exists.
         */
        if (
            _exclusiveDiscount.value > 0 &&
            chapmanConnectStore.hasCustomerData
        ) {
            return normalizePrice(
                _msrp.value,
                _discountedPrice.value - _exclusiveDiscount.value
            );
        }

        /**
         * Show discounted price
         */
        return normalizePrice(_msrp.value, _discountedPrice.value);
    });

    /**
     * The discounted price formatted like $234,000.
     */
    const discountedPriceFormatted = computed(() => {
        if (discountedPriceValue.value <= 0) return 'Call';

        return formatPrice(discountedPriceValue.value);
    });

    const discountedPriceLabel = computed(() => {
        /**
         * Mercedes Compliance
         * Never show any text above the visible price.
         */
        if (isMercedes) return '';

        /**
         * Audi Compliance
         * `MSRP` must be shown as `Total MSRP`
         */
        if (
            isAudi &&
            _isMsrpRequired.value &&
            !chapmanConnectStore.hasCustomerData
        ) {
            return 'Total MSRP';
        }

        /**
         * BMW Compliance
         * `MSRP` must be shown if savings are restricted and customer info does
         * not exist.
         */
        if (
            isBmw &&
            _isMsrpRequired.value &&
            !chapmanConnectStore.hasCustomerData
        ) {
            return 'MSRP';
        }

        /**
         * Restrict savings from being shown on used cars unless customer info
         * exists.
         */
        if (
            !_isVehicleNew.value &&
            _isMsrpRequired.value &&
            !chapmanConnectStore.hasCustomerData
        ) {
            return 'Retail';
        }

        /**
         * Show `MyChapman` on the VDP if exclusive discount exists and customer
         * info exists.
         */
        if (
            _pageType.value === 'VDP' &&
            _exclusiveDiscount.value > 0 &&
            chapmanConnectStore.hasCustomerData
        ) {
            return 'MyChapman Price';
        }

        /**
         * New vehicles with no savings must show `MSRP` on the VDP.
         */
        if (
            _pageType.value === 'VDP' &&
            _isVehicleNew.value &&
            savingsValue.value === 0
        ) {
            return 'MSRP';
        }

        /**
         * Used vehicles with no savings must show `Retail` on the VDP.
         */
        if (
            _pageType.value === 'VDP' &&
            !_isVehicleNew.value &&
            savingsValue.value === 0
        ) {
            return 'Retail';
        }

        /**
         * All vehicles will show `Chapman Price` if no other restrictions are
         * in place and savings exist, only on the VDP.
         */
        if (_pageType.value === 'VDP') {
            return 'Chapman Price';
        }

        /**
         * On SRP, show nothing when savings exists.
         */
        return '';
    });

    const savingsValue = computed(() => {
        // Do not show savings if vehicle does not have a price.
        if (discountedPriceValue.value <= 0) return 0;

        const savings = initialPriceValue.value - discountedPriceValue.value;

        // Do not show tiny tiny savings
        if (savings > 0 && savings < 300) return 0;

        return savings;
    });

    /**
     * The savings formatted like $234,000.
     */
    const savingsFormatted = computed(() => {
        return formatPrice(savingsValue.value);
    });

    return {
        updateValues,
        validDiscounts,

        initialPriceValue,
        initialPriceFormatted,
        initialPriceLabel,

        discountedPriceValue,
        discountedPriceFormatted,
        discountedPriceLabel,

        savingsValue,
        savingsFormatted,
    };
}
